<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-12 14:20:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-21 17:24:29
-->
<template>
  <div class="">
    <FunnelChart ref="FunnelChart" :id="'classify'" :option="chart"></FunnelChart>
  </div>
</template>

<script>
import FunnelChart from './highcharts/FunnelChart.vue'


export default {
  components: {
    FunnelChart
  },
  
  name: '',

  data() {
    return {
      chart: {
        chart: {
          type: 'funnel',
          marginRight: 100,
        },
        title: {
          text: '分类统计',
          x: -50,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: 'none',
              format: '<b>{point.name}</b> ({point.y:,.0f})',
              // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              softConnector: true,
            },
            neckWidth: '30%',
            neckHeight: '25%',
            //-- Other available options
            // height: pixels or percent
            // width: pixels or percent
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: '用户',
            data: [
              ['商用空调', 305],
              [' 空调', 105],
              ['家用电梯', 92],
              ['新风系统', 28],
              ['空气净化', 80],
            ],
          },
        ],
      },
    }
  },

  computed: {},

  created() {},

  methods: {},
}
</script>

<style lang='scss' scoped>
</style>