<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-12 14:23:15
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-12 15:15:09
-->
<template>
  <a-card class="x-bar">
    <div style="width: 80%; height: 100%" :id="id" :option="option"></div>
  </a-card>
</template>
<script>
// 引入highCharts模块
import HighCharts from 'highcharts'
import funnel from 'highcharts/modules/funnel'
funnel(HighCharts)

export default {
  data() {
    return{
      
    }
  },
  props: {
    id: {
      type: String
    },
    option: {
      type: Object
    }
  },
  mounted() {//钩子函数挂载时实例化这个图表
    this.getCharts()
  },
  methods: {
    getCharts() {
      HighCharts.chart(this.id,this.option)
    }
  }
}
</script>

<style scoped>
.x-bar {
  height: 500px;
}
</style>